function Header() {
  return (
    <header style={{textAlignVertical: "center",textAlign: "center"}}>
      <video controls="" autoplay="autoplay" loop="loop" muted="muted" playsinline="">
        <source src="/video.webm" type="video/webm" />
        <source src="/video.mov" type="video/quicktime" />
      </video>
      <div className="headerbg"> </div>
      <h1>We are TinoSoft, a software company for <br/>
        web, apps, mobile and outsourcing.<br/>
        <span>"The only way to do great work is to love what you do."<i> - Steve Jobs</i></span>
      </h1>
    </header>
  );
}
export default Header;
