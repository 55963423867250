import Card from "./Card";
function Services() {
  return (
    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <Card title="Mobile Games & Apps" img="card1.png" text="We have experiences in developing mobile games with Unity / Cocos. Mobile apps with React Native and Flutter." />
                    </div>
                    <div className="col-md-4 mb-2">
                        <Card title="Web Development" img="card2.png" text="We design & programm Web/ Web App/ Realtime Web with PHP, ReactJS, Flutter, Angular and NodeJS" />
                    </div>
                    <div className="col-md-4 mb-2">
                        <Card title="Enterprise Solutions" img="card3.png" text="We provide consulting service package on business management solutions. We support businesses to set practical requirements, raise problems for solution providers" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;
