function Card(props) {
  return (
    <div className="card">
      <br />
      
      <div className="text-center">
        <img style={{borderRadius:5}} alt="card-img" src={"img/"+props.img} className="text-center img-80" />
      </div>
      <div className="text-center">
        <h3 className="card-title">{props.title}</h3>
      </div>
      <div className="p-3">
        <p className="card-text">
           {props.text}
        </p>
      </div>
    </div>
  );
}
export default Card;
