function About() {
  return (
      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src="img/img1.png" className="img-80" style={{borderRadius:10}} />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT US</h2>
            <p className="main-p">
            Tinosoft is an outsourcing company in HCM City, Viet Nam.
            With many experiences in developing apps/games for mobile and web, we will bring true value to customers.
            </p>
          </div>
        </div>
      </div>
  );
}
export default About;
